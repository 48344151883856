const BookmarkSvgIcon = ({ className, filled = false }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule={filled ? 'nonzero' : 'evenodd'}
      clipRule={filled ? 'nonzero' : 'evenodd'}
      d="M6.57271 13.2393C7.46906 12.7982 8.53094 12.7981 9.42729 13.2393L14 15.49V2H2V15.49L6.57271 13.2393ZM0 1.55863V16.9595C0 17.7467 0.925459 18.248 1.68694 17.8732L7.45592 15.0338C7.79537 14.8667 8.20463 14.8667 8.54408 15.0338L14.3131 17.8732C15.0745 18.248 16 17.7467 16 16.9595V1.55863C16 0.697822 15.2325 0 14.2857 0H1.71429C0.767512 0 0 0.697822 0 1.55863Z"
      fill="current"
    />
  </svg>
);

export default BookmarkSvgIcon;
