import Color from 'styles/colors';
import { css } from '@emotion/css';
import { Size, Weight } from 'styles/font';
import { Size as typeofSize, StyleType as typeofStyleType } from './types';

export const tag = (size: typeofSize, styleType: typeofStyleType, disabled: boolean, layout: string) => css`
  ${getTag()}
  ${getSize(size)}
  ${getStyleType(styleType)}
  ${layout}
  ${getDisabled(disabled)}
`;

const getTag = () => css`
  border: none;
  border-radius: 0.25rem;
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;
`;

// 지금은 text가 styled component로 되어있있어서 여기에 각각의 스타일을 넣어 주었지만
// 추후에 emotion / css로 바꾸면 해당 css를 가져오는 방식으로 변경되어야함
const getSize = (size: typeofSize) => {
  switch (size) {
    case 'xxSmall':
      return css`
        height: ${Size.P14};
        padding: 0 0.25rem;
        font-size: ${Size.P10};
        font-weight: ${Weight.Medium};
        line-height: ${Size.P14};
      `;
    case 'xSmall':
      return css`
        height: 1.25rem;
        padding: 0 0.25rem;
        // Body3
        font-size: ${Size.P12};
        font-weight: ${Weight.Medium};
        line-height: ${Size.P16};
      `;
    case 'small':
      return css`
        height: 1.5rem;
        padding: 0 0.375rem;

        // Body3
        font-size: ${Size.P12};
        font-weight: ${Weight.Medium};
        line-height: ${Size.P16};
      `;
    case 'medium':
      return css`
        height: 1.5rem;
        padding: 0 0.75rem;

        // Body2
        height: 1.5rem;
        font-size: ${Size.P14};
        font-weight: ${Weight.Regular};
        line-height: ${Size.P21};
      `;
    default:
  }
};

const getDisabled = (disabled: boolean) => {
  if (disabled) {
    return css`
      background-color: ${Color.Gray['20']};
      color: ${Color.Gray['50']};
    `;
  }
};

const getStyleType = (type: typeofStyleType) => {
  switch (type) {
    case 'primary':
      return css`
        background-color: ${Color.Primary};
        color: ${Color.White};
      `;
    case 'secondary':
      return css`
        background-color: ${Color.Secondary};
        color: ${Color.White};
      `;
    case 'blue':
      return css`
        background-color: ${Color.Blue['20']};
        color: ${Color.Primary};
      `;
    case 'green':
      return css`
        background-color: ${Color.Green['20']};
        color: ${Color.Green['80']};
      `;
    case 'red':
      return css`
        background-color: ${Color.Red['20']};
        color: ${Color.Red['60']};
      `;
    case 'gray':
      return css`
        background-color: ${Color.Gray['20']};
        color: ${Color.Gray['60']};
      `;
    default:
  }
};
