import { AnalyticsContext } from 'contexts/AnalyticsContext';
import AppIcon from 'assets/images/install.jpg';
import { useContext } from 'react';
import * as styles from './styles';

export default function SmartBanner() {
  const { trackEvent } = useContext(AnalyticsContext);

  function onDownloadClick() {
    // window.location.href = 'https://goodoc.onelink.me/7srm/b477e817';
    window.location.href = `https://goodoc.onelink.me/7srm?pid=Eventzone&c=webtoapp&af_dp=${encodeURIComponent(
      'goodoc://app/events'
    )}&af_web_dp=${encodeURIComponent('https://goodoc.co.kr')}&af_adset=web_cm_topbanner&af_ad=01&af_sub1=210803`;
  }

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <img src={AppIcon} className={styles.icon} alt="굿닥" />
        <div className={styles.text}>
          <h1>1000만 명은 이미</h1>
          <p className={styles.description}>앱으로 편하게 굿닥 이용중!</p>
        </div>
        <button className={styles.button} onClick={onDownloadClick}>
          다운로드
        </button>
      </div>
    </section>
  );
}
