import { css } from '@emotion/css';
import Color from 'styles/colors';

export const container = css`
  padding: 1.25rem;
  font-size: 0.75rem;
  line-height: 150%;
  padding-bottom: 6rem;
  background: ${Color.Gray['20']};
  color: ${Color.Gray['60']};
`;

export const divider = css`
  display: inline-block;
  margin-right: 6px;
  margin-left: 4px;

  &:before {
    content: '|';
    opacity: 0.2;
  }
`;

export const content = css`
  margin-bottom: 1rem;
`;
