import { css } from '@emotion/css';
import Color from 'styles/colors';

export const container = css`
  padding: 1rem 1.5rem;
  background: #e6f3ff;
`;

export const content = css`
  display: flex;
  align-items: center;
`;

export const icon = css`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
`;

export const text = css`
  flex: 1;
  margin-left: 0.75rem;
  font-size: 0.875rem;
`;

export const description = css`
  font-weight: bold;
`;

export const button = css`
  background: ${Color.Primary};
  border-radius: 0.5rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  color: white;
`;
