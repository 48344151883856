import dayjs from 'dayjs';
import { calcDistance } from './calculate';

export function getFormattedNumberKo(number: number) {
  const num: any = number ? number.toString() : false;

  const danA = ['', '십', '백', '천', ''];
  let result: any = '';

  if (num.length > 4) {
    for (let i = 0; i < num.length; i++) {
      let str = '';
      const han = num.charAt(num.length - (i + 1)) > 0 ? num.charAt(num.length - (i + 1)) : '0';
      if (i < 3) str += '';
      if (i === 3 && han !== '0') str += han + danA[i];
      if (i > 3) str += han;
      if (i === 4) str += '만';
      if (i === 8) str += '억';
      if (i === 12) str += '조';
      result = str + result;
    }
    if (num) result += '원';
    else result = false;
  } else {
    result = `${number}원`;
  }

  return result;
}

export function getFormattedNumber(inputNumber: number, max?: number): string {
  if (!inputNumber) return '';
  const formattingTo = max && inputNumber > max ? max : inputNumber;
  const formatted = formattingTo.toLocaleString('ko-KR') + (inputNumber !== formattingTo ? '+' : '');

  return formatted;
}

export function getFormattedTime(time: string) {
  const timeArray = time.split(':');
  return `${timeArray[0]}:${timeArray[1]}`;
}

export function getShortAddress(fullAddress: string, fromLevel: number = 0): string {
  if (!fullAddress) return '';
  const firstTwoWords = fullAddress.split(' ').slice(fromLevel, fromLevel + 2);
  const wordsInParentheses = fullAddress.match(/\(([^)]+)\)/);
  const firstWordInParentheses = wordsInParentheses?.[1].split(',')?.[0] || '';

  return [...firstTwoWords, firstWordInParentheses].join(' ').trimRight();
}

export function getFormattedDistance({ currentLocation, latitude, longitude }) {
  const distance = calcDistance([latitude, longitude], [currentLocation?.latitude, currentLocation?.longitude]);
  if (distance < 1000) {
    return `${distance.toFixed(0)}m`;
  }
  return `${(distance / 1000).toFixed(2)}km`;
}

export function flattenObject(obj: Record<string, any>, prefix: string = ''): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      const newPrefix = prefix ? `${prefix}.${key}` : key;
      const flattened = flattenObject(obj[key], newPrefix);
      Object.assign(result, flattened);
    } else {
      const newKey = prefix ? `${prefix}.${key}` : key;
      result[newKey] = obj[key];
    }
  }

  return result;
}

export function formatDate(dateString: string) {
  if (!dateString) return dateString;
  return dayjs(dateString).format('YYYY.MM.DD');
}

export function getFormattedDay(data: string) {
  const day: {
    [key: string]: string;
  } = {
    monday: '월요일',
    tuesday: '화요일',
    wednesday: '수요일',
    thursday: '목요일',
    friday: '금요일',
    saturday: '토요일',
    sunday: '일요일',
    holiday: '공휴일',
    weekdayLunch: '평일 점심시간',
    weekendLunch: '주말 점심시간',
  };

  return day[data];
}
