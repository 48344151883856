import { Coordinate, Address } from 'model';
import React, { useEffect, useState } from 'react';
import { validCoordinateInKorea } from 'utils/validation';

const LOCAL_STORAGE_KEY_LAST_SELECTED_COORDINATE = 'lastSelectedCoordinate';
interface LocationContextProps {
  defaultCoordinate?: Coordinate;
  coordinate?: Coordinate;
  address?: Address;
  setCoordinate?: React.Dispatch<React.SetStateAction<Coordinate>>;
  getCurrentPositionAsync?: (options?: PositionOptions) => Promise<GeolocationPosition>;
}

const defaultCoordinate = {
  latitude: 37.4993461,
  longitude: 127.0288439,
};

const unknownAddress: Address = {
  sido: '',
  sigungu: '알 수 없는 위치',
  dongmyun: '',
  coordinate: null,
};

const LocationContext = React.createContext<LocationContextProps>({});
LocationContext.displayName = 'LocationContext';

const LocationProvider = ({ children }: { children: React.ReactNode }) => {
  const [coordinate, setCoordinate] = useState<Coordinate>(null);
  const [address, setAddress] = useState<Address>(null);

  useEffect(() => {
    const lastSelectedCoordinate = localStorage.getItem(LOCAL_STORAGE_KEY_LAST_SELECTED_COORDINATE);
    if (lastSelectedCoordinate) {
      const { latitude, longitude } = JSON.parse(lastSelectedCoordinate);
      if (validCoordinateInKorea({ latitude, longitude })) {
        setCoordinate({ latitude, longitude });
      } else {
        setCurrentCoordinate();
      }
    } else {
      setCoordinate(defaultCoordinate);
    }
  }, []);

  useEffect(() => {
    if (!coordinate) return;
    convertCoordinateToAddress(coordinate);
    localStorage.setItem(LOCAL_STORAGE_KEY_LAST_SELECTED_COORDINATE, JSON.stringify(coordinate));
  }, [coordinate?.longitude, coordinate?.latitude]);

  const getCurrentPositionAsync = (options: PositionOptions = {}): Promise<GeolocationPosition> =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });

  const setCurrentCoordinate = async () => {
    try {
      const position = await getCurrentPositionAsync();

      const { latitude, longitude } = position.coords;
      if (validCoordinateInKorea({ latitude, longitude })) {
        setCoordinate({ latitude, longitude });
      } else {
        setCoordinate(defaultCoordinate);
      }
    } catch (error) {
      setCoordinate(defaultCoordinate);
      console.error('Error:', error);
    }
  };

  function convertCoordinateToAddress(coordinate) {
    if (!coordinate) {
      setAddress(unknownAddress);
      return;
    }
    const { naver } = window;
    const latlng = new naver.maps.LatLng(coordinate.latitude, coordinate.longitude);

    naver.maps.Service.reverseGeocode(
      {
        coords: latlng,
        orders: [naver.maps.Service.OrderType.ADDR, naver.maps.Service.OrderType.ROAD_ADDR].join(','),
      },
      (status, response) => {
        if (status === naver.maps.Service.Status.ERROR || response?.v2?.results?.length === 0) {
          setAddress(unknownAddress);
          return;
        }
        const {
          region: { area1: sido, area2: sigungu, area3: dongmyun },
        } = response.v2.results[0];

        setAddress({
          sido: sido.name,
          sigungu: sigungu.name,
          dongmyun: dongmyun.name,
          coordinate,
        });
      }
    );
  }

  return (
    <LocationContext.Provider
      value={{
        defaultCoordinate,
        coordinate,
        setCoordinate,
        getCurrentPositionAsync,
        address,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
