export const redirectImageUrl = (url: string, width: number) => {
  if (!url) return url;

  const cdnUrlMap: Record<string, string> = {};

  for (const [oldUrl, newUrl] of Object.entries(cdnUrlMap)) {
    if (url.startsWith(oldUrl)) {
      return `${url.replace(oldUrl, newUrl)}?format=webp&width=${width.toString()}`;
    }
  }

  return url;
};
