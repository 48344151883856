import React, { useEffect, useState } from 'react';
import getUserAgent from 'utils/userAgent';
import AppFooter from 'components/Layout/Footer';
import SmartBanner from './SmartBanner';

export default function AppContainer({ children }: any) {
  const [userAgent, setUserAgent] = useState('');
  useEffect(() => {
    setUserAgent(getUserAgent());
  }, []);
  return (
    <div>
      {userAgent === 'BROWSER' ? (
        <>
          <SmartBanner />
          {children}
          <AppFooter />
        </>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
}
