import { useRef, useEffect } from 'react';

const FixSwipeable = ({ children }) => {
  const container = useRef(null);

  const isolateTouch = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const containerNode = container.current;

    if (!containerNode) {
      return undefined;
    }

    containerNode.addEventListener('touchstart', isolateTouch, { passive: true });
    containerNode.addEventListener('touchmove', isolateTouch, { passive: true });
    containerNode.addEventListener('touchend', isolateTouch, { passive: true });

    return () => {
      containerNode.removeEventListener('touchstart', isolateTouch, { passive: true });
      containerNode.removeEventListener('touchmove', isolateTouch, { passive: true });
      containerNode.removeEventListener('touchend', isolateTouch, { passive: true });
    };
  }, []);

  return <div ref={container}>{children}</div>;
};

export default FixSwipeable;
