import { FC, HTMLAttributes } from 'react';
import * as styles from './styles';

interface SpacerProps extends HTMLAttributes<HTMLDivElement> {
  width?: number;
  height?: number;
}

const Spacer: FC<SpacerProps> = ({ width = 0, height = 0 }) => <div className={styles.getSize(width, height)} />;

export default Spacer;
