import React, { PropsWithChildren } from 'react';
import { TagProps } from './types';
import * as styles from './styles';

const Tag = ({
  layout = '',
  children,
  size = 'xSmall',
  styleType = 'primary',
  disabled = false,
  onClick = () => null,
  ...props
}: PropsWithChildren<TagProps>) => (
  <span
    className={styles.tag(size, styleType, disabled, layout)}
    onClick={onClick}
    {...props}
  >
    {children}
  </span>
);

export default Tag;
