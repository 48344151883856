import React, { ReactNode, PropsWithChildren } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';
import Tag from '../Tag';
import { TagProps } from '../types';

interface WithImageProps extends TagProps {
  leftImage?: ReactNode;
  rightImage?: ReactNode;
}

const WithImage = (withImage: PropsWithChildren<WithImageProps>) => {
  const {
    children, layout, leftImage, rightImage, ...restProps
  } = withImage;
  const layoutAddedProps = {
    layout: cx(layout, styles.imageGap),
    ...restProps,
  };
  return (
    <Tag {...layoutAddedProps}>
      {leftImage}
      {children}
      {rightImage}
    </Tag>
  );
};

export default WithImage;
