import { apolloClinicNewLink } from 'graphql/apollo';
import { useBookmarkClinicEventIdsQuery, useBookmarksLazyQuery } from 'graphql/generated/graphql';
import { Bookmark } from 'model/graphql';
import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { UserContext } from 'contexts/UserContext';

const RECENT_BOOKMARKS_COUNT = 10;
interface UseBookmarkListResult {
  fetchBookmarks: () => void;
  fetchMoreBookmarks: () => boolean;
  bookmarks: Bookmark[];
  recentBookmarks: Bookmark[];
  isMoreThanRecentCount: boolean;
  isEndOfTheList: boolean;
  isBookmarkLoading: boolean;
}

export const useBookmarkIds = () => {
  const { isSignedIn } = useContext(UserContext);
  const [bookmarkClinicEventIds, setBookmarkClinicEventIds] = useState<Array<String>>([]);

  const { data, refetch, called } = useBookmarkClinicEventIdsQuery({
    client: apolloClinicNewLink,
    fetchPolicy: 'cache-and-network',
    variables: {},
  });

  const setData = (data) => {
    if (!data) return;
    setBookmarkClinicEventIds(data?.bookmarks?.list?.map((bookmark) => bookmark.clinicEventId) || []);
  };

  const refetchBookmarkIds = useCallback(async () => {
    if (isSignedIn) {
      const refetchResult = await refetch();

      setData(refetchResult?.data);
    } else {
      setBookmarkClinicEventIds([]);
    }
  }, [refetch, isSignedIn]);

  useEffect(() => {
    setData(data);
  }, [data]);

  // 로그인 상태가 변경 되면 무조건 refetch or 초기화
  useEffect(() => {
    refetchBookmarkIds();
  }, [isSignedIn]);

  return {
    refetchBookmarkIds,
    bookmarkClinicEventIds,
    firstFetched: called,
  };
};

export const useBookmarkList = (): UseBookmarkListResult => {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEndOfTheList, setEndOfTheList] = useState(false);

  const [queryBookmarks, { data, loading: isBookmarkLoading }] = useBookmarksLazyQuery({
    client: apolloClinicNewLink,
    fetchPolicy: 'no-cache',
  });

  const fetchBookmarks = () => {
    setEndOfTheList(false);
    const initialPage = 0;
    setCurrentPage(initialPage);
    queryBookmarks({
      variables: { pagination: { skip: initialPage * RECENT_BOOKMARKS_COUNT, take: RECENT_BOOKMARKS_COUNT } },
    });
  };

  const fetchMoreBookmarks = () => {
    if (isEndOfTheList) return false;
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    queryBookmarks({
      variables: { pagination: { skip: nextPage * RECENT_BOOKMARKS_COUNT, take: RECENT_BOOKMARKS_COUNT } },
    });
    return true;
  };

  useEffect(() => {
    if (!data) return;

    setBookmarks((prev) => {
      const bookmarksData = [...(currentPage > 0 ? prev : []), ...(data?.bookmarks.list || [])];
      setEndOfTheList(data.bookmarks.totalCount <= bookmarksData.length);
      return bookmarksData;
    });
  }, [data]);

  const recentBookmarks = useMemo(() => bookmarks?.slice(0, RECENT_BOOKMARKS_COUNT), [bookmarks]);
  const isMoreThanRecentCount = useMemo(() => bookmarks?.length > RECENT_BOOKMARKS_COUNT, [bookmarks]);

  return {
    fetchBookmarks,
    fetchMoreBookmarks,
    bookmarks,
    recentBookmarks,
    isMoreThanRecentCount,
    isEndOfTheList,
    isBookmarkLoading,
  };
};

export default { useBookmarkList };
