import React from 'react';
import * as styles from './styles';

export default function Footer() {
  return (
    <footer className={styles.container}>
      <div className={styles.content}>
        <a href="https://goodoc.io" target="_blank" rel="noreferrer">
          회사소개
        </a>
        <div className={styles.divider} />
        <a href="https://link.goodoc.co.kr/privacy-policy-goodoc" target="_blank" rel="noreferrer">
          <strong>개인정보 처리방침</strong>
        </a>
        <div className={styles.divider} />
        <a href="https://link.goodoc.co.kr/terms-service-goodoc" target="_blank" rel="noreferrer">
          이용약관
        </a>
      </div>
      <div className={styles.content}>
        <span>상호명 : </span>
        주식회사 굿닥
        <div className={styles.divider} />
        <span>대표자 : </span>
        임진석
        <br />
        <span>주소 : </span>
        서울특별시 강남구 역삼로 2길 16, 6~9층
        <br />
        <span>고객센터 : </span>
        <a href="tel:1661-8173">1661-8173</a>
        <div className={styles.divider} />
        <span>사업자등록번호 : </span>
        431-88-01818
        <br />
        <span>통신판매업신고번호 : </span>
        제2020-서울강남-00257호
      </div>
      <br />
    </footer>
  );
}
