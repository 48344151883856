export function calcDistance(coord1: [number, number], coord2: [number, number]): number {
  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;

  const lat1Rad = (lat1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lon1Rad = (lon1 * Math.PI) / 180;
  const lon2Rad = (lon2 * Math.PI) / 180;

  const a =
    Math.sin((lat2Rad - lat1Rad) / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin((lon2Rad - lon1Rad) / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const earthRadiusM = 6371000;
  return earthRadiusM * c;
}

export function calcCareerYear(openAt: string): string {
  const openYear = new Date(openAt)?.getFullYear();
  const currentYear = new Date().getFullYear();
  const careerYear = currentYear - openYear + 1;
  return careerYear.toString();
}

export function calcDiscountRate(price: number, originalPrice: number): string {
  if (!originalPrice) return '0';

  const discountRate = Math.max(Math.floor(((originalPrice - price) / originalPrice) * 100), 0);
  return discountRate.toFixed(0);
}
